.banner-container {
  background-color: #ff6347;
  color: white;
  padding: 10px 0;
  text-align: center;
  position: relative;
  border-color: #ff6347;
}

.banner-content h1 {
  font-size: 1.2rem;
  margin: 0;
}

.banner-content p {
  font-size: 1rem;
  margin: 10px 0 0;
  padding: 10px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}
