@import "./variables.scss";

body {
  background-color: #f7f5f5;
  font-family: Arial, Helvetica, sans-serif;
  color: $blue-grey1;
}
html {
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  word-break: normal;
  tab-size: 4;
}
.root {
  width: 100%;
  font-size: 1rem;
  color: $blue-grey1;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // color: #000133;
}
.container {
  position: relative;
  display: flex;
  flex: 1;
}
.pa16 {
  padding: 16px;
}
.pb84 {
  padding-bottom: 84px;
}
.sidebar {
  position: "relative";

  .MuiDrawer-paper {
    top: inherit !important;
    overflow: overlay !important;
  }
  .hide {
    display: none;
  }
  .show {
    display: flex !important;
  }
}
.dot {
  height: 16px;
  width: 16px;
  min-width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
}
.drawer {
  background: white;
  width: 216px;
  .sidebar {
    svg {
      margin-right: 32px;
    }
    .sidebarItem {
      margin: 0;
      padding: 0;
      .icon {
        height: 24px;
        width: 24px;
      }
      a {
        padding-left: 16px;
        padding-right: 16px;
        flex: 1 1 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        align-items: center;
        display: flex;
        letter-spacing: normal;
        min-height: 55px;
        outline: none;
        position: relative;
        color: $black;
        text-decoration: none;

        &.selectedTab {
          color: $orange1 !important;
          caret-color: $orange1 !important;
        }
        &.link {
          color: $black;
          font-family: Arial;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 18px;
          &.secondary {
            color: $grey2;
            caret-color: $grey2;
          }
          &.selected {
            color: $orange1 !important;
            caret-color: $orange1 !important;
          }
        }
      }
    }
  }
}

.dimmed {
  opacity: 0.3;
  pointer-events: none;
}
.heading22 {
  font-size: 22px;
}
.page {
  flex: 1;
  flex-grow: 1;
  background-color: #f7f5f5;
  position: absolute;
  width: 100%;

  @include lg {
    position: unset;
  }
}
//   .card {
//     padding: 16px;
//   }
//   .titleBlock {
//     padding-left: 16px;
//     padding-right: 16px;
//     margin-top: 40px;
//   }
// }
