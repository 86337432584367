$body-font-family: Arial, Helvetica, sans-serif;
$calendar-weekly-day-label-font-size: 18px;
$calendar-weekly-weekday-font-size: 18px;
$calendar-weekly-weekday-padding: 25px 0px;
$calendar-weekly-day-padding: 10px;
$btn-toggle-btn-opacity: 1 !default;
$darkBlueGrey: #466371;

$orange1: #e65400;
$orange2: #f77929;
$orange3: #fab636;
$orange4: #f8d595;

$purple1: #761f6a;
$purple2: #9f2a95;
$purple3: #d04c95;
$purple4: #e8c8e0;

$blue1: #224483;
$blue2: #1866b0;
$blue3: #2da2d7;
$blue4: #acd3f4;

$turquoise1: #0b637b;
$turquiose2: #0c8fa5;
$turquoise3: #52d8e5;
$turquoise4: #abe8e3;

$green1: #018882;
$green2: #01aa89;
$green3: #b3de68;
$green4: #e1f199;

$blue-grey1: #466371;
$blue-grey2: #5c879c;
$blue-grey3: #90b0bf;
$blue-grey4: #d7dfe2;

$grey1: #50524d;
$grey2: #84898f;
$grey3: #bcbcba;
$grey4: #e6e8d3;

$black: #252525;
$web-grey: #f2f0e9;
$white: #ffffff;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 960px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
